export function customFetch(url, opts = {}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(opts.method || 'get', url);

    for (const k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }

    xhr.onload = evt => {
      resolve(
        new Response(xhr.response, {
          status: evt.target.status,
          statusText: evt.target.statusText,
          headers: opts.headers,
        })
      );
    };

    xhr.onerror = reject;

    if (xhr.upload) {
      xhr.upload.onprogress = event =>
        opts?.onUploadProgress?.({
          loaded: event.loaded,
          total: event.total,
          progress: (event.loaded / event.total) * 100,
        });
    }

    xhr.send(opts.body);
  });
}
