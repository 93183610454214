import { Box, useThemeUI } from 'theme-ui';

const EditIcon = ({ width, color, sx }) => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      sx={{
        minWidth: width || '16px',
        width: width || '16px',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <path
        d="M8.44488 9.42419L5.97021 9.77819L6.32355 7.30285L12.6875 0.938854C12.9689 0.657548 13.3504 0.499512 13.7482 0.499512C14.146 0.499512 14.5276 0.657548 14.8089 0.938854C15.0902 1.22016 15.2482 1.60169 15.2482 1.99952C15.2482 2.39735 15.0902 2.77888 14.8089 3.06019L8.44488 9.42419Z"
        fill={color || theme.colors.primary}
        stroke={color || theme.colors.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.748 9.5V14.5C12.748 14.7652 12.6427 15.0196 12.4552 15.2071C12.2676 15.3946 12.0133 15.5 11.748 15.5H1.74805C1.48283 15.5 1.22848 15.3946 1.04094 15.2071C0.853404 15.0196 0.748047 14.7652 0.748047 14.5V4.5C0.748047 4.23478 0.853404 3.98043 1.04094 3.79289C1.22848 3.60536 1.48283 3.5 1.74805 3.5H6.74805"
        stroke={color || theme.colors.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  );
};

export default EditIcon;
