// Hey! 👋 The custom suggestions dropdown styles are handeled in ./autocomplete.css

import React, { useState, useRef } from 'react';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Buffer } from 'buffer';

import { formatResult } from 'lib/geocoding';
import { TYPES, REGION, FIELDS } from 'constants/google';

import './autocomplete.css';

const Autocomplete = props => {
  const autocompleteRef = useRef(null);
  const placesRef = useRef(null);

  const location = useLocation();
  const searchParams = queryString.parse(location?.search);
  const address = searchParams?.isEncoded
    ? Buffer.from(searchParams.address, 'base64')
    : searchParams?.address;

  const [searchValue, setSearchValue] = useState(props?.defaultValue || '');

  // If the user clicks enter instead of selecting from the
  // autocomplete dropdown, this will do a query search using
  // the first suggestion in the dropdown
  const findPlaceFromQuery = async query => {
    if (query === '') {
      return false;
    }

    const firstSuggestion = await getFirstSuggestion(query);

    if (!firstSuggestion) {
      // when no search result exist, set the input with the query from the url and return
      setSearchValue(query);
      return false;
    }
    if (!placesRef?.current) {
      placesRef.current = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
    }

    placesRef.current.findPlaceFromQuery(
      {
        query: firstSuggestion,
        fields: ['place_id'],
      },
      res => {
        placesRef.current.getDetails(
          {
            placeId: res[0].place_id,
            fields: FIELDS,
          },
          res => prepareResult(res)
        );
      }
    );

    return true;
  };

  const getFirstSuggestion = query => {
    return new Promise(resolve => {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: query,
          types: TYPES,
          componentRestrictions: { country: REGION },
        },
        res => {
          resolve(res?.[0]?.description);
        }
      );
    });
  };

  const prepareResult = async res => {
    const details = await formatResult(res);
    setSearchValue(details.address);
    props.onSelect(details?.address || props?.defaultValue);
    props.setResults(details);
  };

  const onLoad = autocomplete => {
    // doesn't get removed properly in an SPA
    document.querySelector('.pac-container')?.remove();

    autocompleteRef.current = autocomplete;

    if (address) {
      findPlaceFromQuery(address);
    }
  };

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();

    if (place?.place_id) {
      return prepareResult(place);
    }

    if (place?.name) {
      return findPlaceFromQuery(place.name.trim());
    }

    return false;
  };

  return (
    <GoogleAutocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      options={{
        types: TYPES,
        fields: FIELDS,
        componentRestrictions: { country: REGION },
      }}
    >
      <input
        placeholder="Enter a suburb or postcode"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        style={{
          width: '100%',
          minHeight: '48px',
          lineHeight: '1.5',
          fontSize: '1rem',
          color: '#202938',
          borderColor: '#D2D5DC',
          borderWidth: '1px',
          borderStyle: 'solid',
          marginTop: '24px',
          border: '0px none',
          padding: '1rem 3.5rem 1rem 1rem',
          boxShadow: 'rgb(138, 138, 138) 0px -2px 0px 0px inset',
          borderRadius: '0px',
          appearance: 'textfield',
        }}
      />
    </GoogleAutocomplete>
  );
};

export default React.memo(Autocomplete);
