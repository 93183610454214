import { useSearchParams } from 'react-router-dom';

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = {};
  for (const [key, value] of searchParams.entries()) {
    query[key] = value;
  }

  const setQueryParams = (newQuery, options = {}) => {
    for (const [key, value] of Object.entries(newQuery)) {
      searchParams.set(key, value);
    }
    setSearchParams(searchParams, options);
  };

  return { queryParams: query, setQueryParams };
};

export default useQueryParams;
