import { Paragraph, Button } from 'theme-ui';
import EditIcon from 'components/common/Icons/EditIcon';

const EditButton = ({ onEditClick }) => {
  return (
    <Button
      variant="minimal"
      onClick={onEditClick}
      sx={{
        display: 'flex',
      }}
    >
      <EditIcon
        sx={{
          marginRight: '8px',
          marginTop: '2px',
        }}
      />
      <Paragraph
        sx={{
          color: 'primary',
        }}
      >
        Edit
      </Paragraph>
    </Button>
  );
};

export default EditButton;
