import { Flex, Paragraph } from 'theme-ui';
import { FieldWrapper, FieldHeading } from 'components/common/FieldCard';
import EditButton from 'components/common/EditButton';

export const ReviewWrapper = ({ children, title, onEditClick }) => {
  return (
    <FieldWrapper>
      <Flex
        sx={{
          flexDirection: ['column', 'row', 'row'],
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gridGap: '16px',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            flexWrap: 'nowrap',
            flex: 1,
          }}
        >
          <FieldHeading>{title}</FieldHeading>
          <Flex
            sx={{
              flexDirection: 'column',
              flexWrap: 'nowrap',
              gridGap: '16px',
            }}
          >
            {children}
          </Flex>
        </Flex>
        {onEditClick ? <EditButton onEditClick={onEditClick} /> : null}
      </Flex>
    </FieldWrapper>
  );
};

export const ReviewRow = ({ children }) => {
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row', 'row'],
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      {children}
    </Flex>
  );
};

export const ReviewLabel = ({ text }) => {
  return (
    <Paragraph
      sx={{
        fontSize: 2,
        fontWeight: 'normal',
        minWidth: ['0', '256px', '256px'],
        width: ['100%', '256px', '256px'],
        marginRight: ['0', '16px', '16px'],
      }}
    >
      {text}:
    </Paragraph>
  );
};

export const ReviewValue = ({ text }) => {
  return (
    <Paragraph
      sx={{
        fontSize: 2,
        fontWeight: 'light',
        color: 'grey1000',
        width: '100%',
      }}
    >
      {text}
    </Paragraph>
  );
};
