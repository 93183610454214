import { Box, Heading, Flex } from 'theme-ui';

const FormWrapper = ({ children, title, alert }) => {
  return (
    <Box
      sx={{
        maxWidth: '694px',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Heading
        as="h2"
        variant="styles.h2"
        sx={{
          textAlign: 'center',
          marginBottom: ['16px', '32px', '32px'],
        }}
      >
        {title}
      </Heading>
      <Flex
        sx={{
          flexDirection: 'column',
          flexWrap: 'nowrap',
          gridGap: ['12px', '12px'],
        }}
      >
        {alert}
        {children}
      </Flex>
    </Box>
  );
};

export default FormWrapper;
