import { gql } from 'graphql.macro';

export const RequestAppointmentMutation = gql`
  mutation RequestAppointmentMutation(
    $validation: ValidationInput!
    $input: RequestAppointmentInput!
  ) {
    requestAppointment(validation: $validation, input: $input) {
      id
      submittedAt
    }
  }
`;
