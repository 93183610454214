import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Paragraph } from 'theme-ui';
import { useRecaptcha } from 'hooks/useRecaptcha';
import Loader from 'components/common/Loader';

const Recaptcha = ({ setRecaptchaWidget }) => {
  const { touched, errors, values, setFieldValue } = useFormikContext();

  const { recaptchaStatus, recaptchaLoaded, recaptchaWidget } = useRecaptcha({
    containerId: 'g-recaptcha',
    successCallback: response => setFieldValue('recaptcha', response),
    expiredCallback: () => setFieldValue('recaptcha', ''),
    sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  });

  useEffect(() => {
    setRecaptchaWidget(recaptchaWidget);
  }, [recaptchaWidget, setRecaptchaWidget]);

  if (recaptchaStatus === 'error') {
    return (
      <Paragraph variant="error">
        Google ReCAPTCHA failed to load, please try again. If the problem
        persists across browsers, please contact an administrator.
      </Paragraph>
    );
  }

  if (!recaptchaLoaded) {
    return (
      <Loader
        width={78}
        height={78}
        style={{ justifyContent: 'flex-start', height: 78 }}
      />
    );
  }

  return (
    <>
      <div id="g-recaptcha" className="g-recaptcha" style={{ height: 78 }} />
      {(touched?.['recaptcha'] || values?.['recaptcha']) &&
        errors?.['recaptcha'] && (
          <Paragraph variant="error">{errors?.['recaptcha']}</Paragraph>
        )}
    </>
  );
};

export default Recaptcha;
