import React from 'react';
import { ThemeProvider } from 'theme-ui';
import { ApolloProvider } from '@apollo/client/react/context';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'react-dates/initialize';
import BookingForm from 'components/Forms/BookingForm';
import { BrowserContext } from 'context';
import {
  isMobile,
  isDevelopment,
  isProduction,
  isRunningOnClientSide,
  isRunningOnServerSide,
} from 'lib/util';
import theme from 'lib/theme';
import client from 'lib/client';
import './App.css';

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <BrowserContext.Provider
          value={{
            isMobile,
            isProduction,
            isDevelopment,
            isRunningOnClientSide: isRunningOnClientSide(),
            isRunningOnServerSide: isRunningOnServerSide(),
          }}
        >
          <ApolloProvider client={client}>
            <Routes>
              <Route path="*" element={<BookingForm />} />
            </Routes>
          </ApolloProvider>
        </BrowserContext.Provider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
