import { Box, useThemeUI } from 'theme-ui';

const InfoIcon = ({ width, color, backgroundColor, sx }) => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      fill="transparent"
      viewBox="0 0 24 24"
      sx={{
        minWidth: width || '20px',
        width: width || '20px',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <path
        strokeWidth="2px"
        stroke={color || theme.colors.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.971 22.146l.012.007c1.039.662 2.272.949 3.496.815 1.224-.134 2.366-.682 3.237-1.553l.756-.758c.338-.338.528-.797.528-1.275 0-.478-.19-.937-.528-1.275l-3.187-3.188c-.168-.168-.367-.301-.585-.392-.22-.09-.454-.137-.69-.137-.238 0-.472.046-.691.137-.22.09-.418.224-.585.392v0c-.339.338-.797.527-1.276.527-.478 0-.936-.19-1.274-.527l-5.103-5.1c-.167-.168-.3-.367-.391-.586-.09-.219-.138-.454-.138-.69 0-.237.047-.472.138-.691.09-.219.224-.418.391-.585.338-.338.528-.797.528-1.275 0-.479-.19-.937-.528-1.275L5.893 1.528C5.554 1.19 5.096 1 4.618 1c-.479 0-.937.19-1.275.528l-.758.758c-.87.87-1.419 2.012-1.553 3.236-.134 1.224.153 2.458.815 3.497l.007.012c3.495 5.17 7.947 9.621 13.117 13.115v0z"
      />
    </Box>
  );
};

export default InfoIcon;
