export const getLatLng = result =>
  new Promise((resolve, reject) => {
    try {
      const { lat, lng } = result.geometry.location;

      resolve({ lat: lat(), lng: lng() });
    } catch (error) {
      reject(error);
    }
  });

export const getGeocode = args => {
  const geocoder = new window.google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode(args, (results, status) => {
      if (status !== 'OK') {
        reject(status);
      }
      if (!args.address && args.componentRestrictions) {
        console.error('error');
        resolve(results);
      }
      resolve(results);
    });
  });
};

export const getAddressComponent = (result, type, key = 'short_name') => {
  const component = result.address_components.find(ac =>
    ac.types.includes(type)
  );

  return component ? component[key] : null;
};

export const formatResult = async result => {
  const coords = await getLatLng(result);

  return {
    country: getAddressComponent(result, 'country', 'long_name'),
    suburb: getAddressComponent(result, 'locality'),
    state: getAddressComponent(result, 'administrative_area_level_1'),
    postcode: getAddressComponent(result, 'postal_code'),
    address: result.formatted_address,
    placeId: result.place_id,
    latitude: coords.lat,
    longitude: coords.lng,
  };
};
