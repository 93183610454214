import { Box, useThemeUI } from 'theme-ui';

const ClockIcon = ({ width, color, sx }) => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      sx={{
        minWidth: width || '20px',
        width: width || '20px',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <circle
        cx="10"
        cy="10"
        r="9.25"
        stroke={color || theme.colors.primary}
        strokeWidth="1.5"
      />
      <path
        stroke={color || theme.colors.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10 4.167V10h5.832"
      />
    </Box>
  );
};

export default ClockIcon;
