import React, { useEffect, useRef, useState } from 'react';
import { Paragraph } from 'theme-ui';
import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';

const StyledButton = styled.button`
  ...${props => props.styles};
  display: ${props => (props.expanded === undefined ? 'none' : 'inline-block')};
  content: '${props => 'Show ' + (props.expanded ? 'less' : 'more')}';
  cursor: pointer;
  background: white;
  color: #255ec1;
  text-decoration: underline;
  border: none;
  width: fit-content;
  padding: 5px 0;
`;

const ShowMoreText = ({ children, numberOfLines = 1, styles = {} }) => {
  const paragraphRef = useRef();
  const [expanded, setExpanded] = useState();

  useEffect(() => {
    const getNumberOfLines = element => {
      const clientHeight = parseFloat(
        window.getComputedStyle(element, null).getPropertyValue('height')
      );
      const lineHeight = parseFloat(
        window.getComputedStyle(element, null).getPropertyValue('line-height')
      );

      return Math.round(clientHeight / lineHeight);
    };

    const computedLines = getNumberOfLines(paragraphRef.current);
    setExpanded(computedLines > numberOfLines ? false : undefined);
  }, [numberOfLines, children]);

  return (
    <Flex flexDirection="column">
      <Paragraph
        ref={paragraphRef}
        sx={{
          ...styles,
          width: '100%',
          position: 'relative',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          whiteSpace: 'pre-line',
          WebkitLineClamp: [undefined, true].includes(expanded)
            ? undefined
            : numberOfLines,
        }}
      >
        {children}
      </Paragraph>
      <StyledButton
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
        styles={styles}
      >
        Show {expanded ? 'less' : 'more'}
      </StyledButton>
    </Flex>
  );
};

export default ShowMoreText;
