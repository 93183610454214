import React, { useRef, useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FormWrapper from 'components/common/FormWrapper';
import FormButton from 'components/common/FormButton';
import Alert from 'components/common/Alert';
import { FieldWrapper } from 'components/common/FieldCard';
import { Input, Radio, Select } from 'components/common/Fields';
import scrollToElement from 'lib/scrollToElement';

const PatientDetails = ({ setStep }) => {
  const detailsRef = useRef(null);
  const { values, errors, isValid } = useFormikContext();
  useEffect(() => {
    if (detailsRef?.current && values.appointmentFor) {
      scrollToElement(detailsRef.current);
    }
  }, [values.appointmentFor, detailsRef]);

  return (
    <FormWrapper
      title="Patient details"
      alert={
        <Alert variant="info">
          All fields are required unless otherwise indicated.
        </Alert>
      }
    >
      <FieldWrapper title="Who is the appointment for?">
        <Radio
          name="appointmentFor"
          choices={[
            {
              label: 'Myself',
              value: 'Myself',
            },
            {
              label: 'Someone else',
              value: 'Someone else',
            },
          ]}
        />
      </FieldWrapper>

      {values.appointmentFor && (
        <FieldWrapper title="Your details" innerRef={detailsRef}>
          <Select
            name="title"
            label="Title"
            choices={[
              { value: 'Mr', label: 'Mr' },
              { value: 'Mrs', label: 'Mrs' },
              { value: 'Ms', label: 'Ms' },
              { value: 'Miss', label: 'Miss' },
            ]}
          />
          <Input name="firstName" label="First name" required />
          <Input name="lastName" label="Last name" required />
          <Input name="contactNumber" label="Contact number" required />
          <Input name="email" label="Email" type="email" required />

          {values.appointmentFor === 'Myself' && (
            <Input
              name="dob"
              label="Date of birth"
              type="date"
              error={errors.dob}
              required
            />
          )}

          {values.appointmentFor === 'Someone else' && (
            <>
              <Select
                name="relationship"
                label="Relationship to patient"
                choices={[
                  { value: 'Parent', label: 'Parent' },
                  { value: 'Child', label: 'Child' },
                  { value: 'Sibling', label: 'Sibling' },
                  { value: 'Carer', label: 'Carer' },
                  { value: 'Other', label: 'Other' },
                ]}
                required
              />

              {values.relationship === 'Other' && (
                <Input
                  name="otherRelationship"
                  label="Describe relationship to the patient"
                  required
                />
              )}
            </>
          )}
        </FieldWrapper>
      )}

      {values.appointmentFor === 'Someone else' && (
        <FieldWrapper title="Patient details">
          <Select
            name="patientTitle"
            label="Title"
            choices={[
              { value: 'Mr', label: 'Mr' },
              { value: 'Mrs', label: 'Mrs' },
              { value: 'Ms', label: 'Ms' },
              { value: 'Miss', label: 'Miss' },
            ]}
          />
          <Input name="patientFirstName" label="First name" />
          <Input name="patientLastName" label="Last name" />
          <Input name="dob" label="Date of birth" type="date" />
        </FieldWrapper>
      )}

      <FormButton
        onClick={() => setStep(4, values)}
        disabled={!isValid}
        text="Continue to review &amp; confirm"
      />
    </FormWrapper>
  );
};

PatientDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default PatientDetails;
