export const LIBRARIES = ['places'];

export const TYPES = [
  'locality',
  'sublocality',
  'administrative_area_level_1',
  'postal_code',
  'point_of_interest',
];

export const REGION = 'au';

export const FIELDS = [
  'place_id',
  'formatted_address',
  'address_components',
  'geometry',
  'types',
];
