import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import moment from 'moment-timezone';
import { Box, Button, Paragraph, useThemeUI } from 'theme-ui';

import { Flex, Button as RebassButton } from 'rebass';
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useFormikContext } from 'formik';
import usePersistedCacheQuery from 'hooks/usePersistedCacheQuery';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { DayPickerSingleDateController } from 'react-dates';
import isSameDay from 'react-dates/lib/utils/isSameDay';

import client from 'lib/client';
import useIsMobile from 'hooks/useIsMobile';
import scrollToElement from 'lib/scrollToElement';
import { hasExistingEvent, trackEvent, trackInputEvent } from 'lib/GA';

import { StandardText } from 'constants/typography';
import Loader from 'components/common/Loader';

import { LocationSlotsQuery } from 'queries/centres.js';

import 'react-dates/lib/css/_datepicker.css';

import FormWrapper from 'components/common/FormWrapper';
import FormButton from 'components/common/FormButton';
import Alert from 'components/common/Alert';
import { FieldWrapper } from 'components/common/FieldCard';
import { Radio, Select } from 'components/common/Fields';
import ThrottleAlert from '../../common/ThrottleAlert';
import useEmergencyAppointmentMode from 'hooks/useEmergencyAppointmentMode';
import PhoneNumber from '../../common/PhoneNumber';

const calendarStyles = css`
  .DayPicker {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .DayPicker__withBorder {
    box-shadow: none;
    padding-bottom: 72px;

    @media (min-width: 992px) {
      padding-bottom: 120px;
    }
  }

  .CalendarDay__default {
    border: 4px solid white;
    color: #4c5564;
    background: #fff;
    vertical-align: middle;
    margin: 8px;
    color: inherit;
    font-size: 16px;
    border-radius: 4px;

    > div {
      border: 1px solid rgb(20, 74, 56);
      border-radius: 4px;
    }

    @media (min-width: 992px) {
      height: 60px !important;
    }
  }

  .CalendarMonthGrid_month__hidden {
    height: 0px;
  }

  .DayPickerNavigation {
    height: 32px;
  }

  .DayPickerNavigation_button__default {
    border: none;

    &:before {
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      left: 0.15em;
      position: relative;
      top: 0.15em;
      vertical-align: top;
      width: 0.45em;
      border-color: ${props => props.theme.colors.primary};
    }

    svg {
      display: none;
    }
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    transform: rotate(-135deg);
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    transform: rotate(45deg);
  }

  .CalendarDay__hovered_offset {
    background: #fff;
    border: none !important;
    color: inherit;
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_calendar.CalendarDay__blocked_calendar.CalendarDay__blocked_calendar {
    color: #9ca2ae;
    background-color: #f3f4f6;
    text-decoration: line-through;

    div {
      border: none;
    }

    .actual-date {
      background-color: transparent;
      color: #9ca2ae;
      font-weight: normal;
    }
  }

  .CalendarDay__today,
  .CalendarDay__today:active,
  .CalendarDay__today:hover {
    .actual-date {
      border-radius: 50%;
      height: 30px;
      width: 30px;
      background-color: rgb(20, 74, 56);
      color: white;
      font-weight: bold;
    }
  }
  .CalendarDay__selected .actual-date,
  .CalendarDay__selected:active .actual-date,
  .CalendarDay__selected:hover .actual-date {
    background-color: rgb(20, 74, 56);
    width: 100%;
    height: 100%;
    color: #fff;
  }

  .CalendarMonth.CalendarMonth {
    .CalendarMonth_caption {
      font-size: 20px;
      color: #202938;
      padding-bottom: 30px;
    }
  }

  .DayPicker_weekHeader {
    .DayPicker_weekHeader_li {
      font-size: 16px;
      line-height: 24px;
      color: #202938;
    }
  }

  .actual-date {
    letter-spacing: 1px;
  }

  .DayPickerNavigation {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .DayPickerNavigation_button__verticalDefault {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    width: 100%;
    height: 24px;

    &:before {
      height: 16px;
      width: 16px;
    }

    &:active {
      background-color: transparent;
    }

    &:hover {
      border: none;
    }

    &.DayPickerNavigation_nextButton__verticalDefault_5 {
      align-self: flex-end;

      &:before {
        transform: rotate(135deg);
        top: -10px;
      }
    }

    &.DayPickerNavigation_prevButton__verticalDefault_5 {
      &:before {
        transform: rotate(-45deg) scaleY(1);
      }
    }
  }
`;

const TimeGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 120px));
  padding: 16px 0 24px;

  ${props => props.theme.mq.mdInverted} {
    justify-content: center;
  }

  &[data-bottomborder='true'] {
    border-bottom: solid 1px #d2d5dc;
  }
`;

const SlotContainer = styled(Flex)`
  &:first-of-type {
    margin-top: 0;
  }

  &:last-child {
    ${TimeGrid} {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;

const TimeButton = styled(RebassButton)`
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding: 0 !important;
  border-radius: 4px;
  border: 1px solid #d2d5dc !important;
  color: #4c5564 !important;
  text-align: center;
  cursor: pointer;
  background-color: ${props => (props.selected ? 'rgb(20, 74, 56)' : '#fff')};

  &[data-selected='true'] {
    background-color: rgb(20, 74, 56);
    color: #fff !important;
  }
`;

const SelectAppointment = ({
  locationDetails,
  reasons,
  setStep,
  date,
  setDate,
  selectedSlot,
  setSelectedSlot,
  selectedCentre,
  nextStep,
  specialistSearchMode,
  isValidSpecialist,
  specialist,
  asServiceOption,
  onChangeSpecialist,
}) => {
  const isEmergencyAppointmentMode = useEmergencyAppointmentMode();
  const appointmentDetailsBlock = useRef(null);
  const appointmentDateBlock = useRef(null);
  const appointmentTimeBlock = useRef(null);
  const appointmentConfirmBlock = useRef(null);
  const now = DateTime.local();
  const end = now.plus({ days: isEmergencyAppointmentMode ? 30 : 90 });
  const fourMonths = (end.month - now.month) % 2 !== 0;
  const { values, setFieldValue, resetForm } = useFormikContext();
  const [centre] = useState(selectedCentre || null);
  const [nextMonths, setNextMonths] = useState(1);
  const [isLtDesktop, isTablet, isMobile, isSmallPhone] = useIsMobile(
    1024,
    850,
    640,
    480,
    374
  );
  const [showContactMessage, setShowContactMessage] = useState(false);
  const [showNoDoctorsError, setShowNoDoctorsError] = useState(false);
  const datePickerRef = useRef();
  const serviceRef = useRef();

  const { theme } = useThemeUI();

  const handleOnBlur = e => {
    switch (e.target.name) {
      case 'patientType':
        if (!hasExistingEvent('formElement', 'Patient type')) {
          trackInputEvent('Patient type', centre?.name);
        }
        break;
      case 'service':
        if (!hasExistingEvent('formElement', 'Service')) {
          trackInputEvent('Service', centre?.name);
        }
        break;
      default:
        break;
    }
  };

  const serviceOptions = useMemo(() => {
    const patientType = values.patientType;
    const { exisingSpecialist, newSpecialist } = reasons;
    const placeholder = { label: 'Select an option', value: '' };
    const reasonType = `${patientType}${
      specialistSearchMode ? 'Specialist' : ''
    }`;

    const options = reasons[patientType]
      ? [
          placeholder,
          ...reasons[reasonType]
            ?.filter(r =>
              specialistSearchMode
                ? centre?.specialistReasons?.includes(r.value)
                : true
            )
            ?.map(asServiceOption),
        ]
      : [placeholder];

    const findSelected = reasons => reasons.find(r => r.value === specialist);

    if (specialistSearchMode && !findSelected(options)) {
      const otherOptions =
        patientType === 'new' ? exisingSpecialist : newSpecialist;
      const found = findSelected(otherOptions);
      found && options.push(asServiceOption(found));
    }

    return options;
  }, [
    specialist,
    specialistSearchMode,
    reasons,
    values.patientType,
    asServiceOption,
    centre?.specialistReasons,
  ]);

  useEffect(() => {
    setFieldValue('service', isValidSpecialist ? specialist : '');
  }, [
    isValidSpecialist,
    specialist,
    setFieldValue,
    centre?.specialistReasons,
    asServiceOption,
  ]);

  // send GTM event when a date & time have been selected
  useEffect(() => {
    // only send events once
    const existing = window?.dataLayer.find(
      event => event?.event === 'chooseAppointmentDateandTime'
    );
    if (!existing && selectedSlot) {
      try {
        trackEvent(
          {
            event: 'chooseAppointmentDateandTime',
            enquiryType: 'bookingWidget',
            appointmentStatus: 'edited',
            centerName: `NIB ${centre?.name}`,
            patientType: (
              values.patientType?.split(' ')[0] || ''
            ).toUpperCase(),
            service: values?.service,
            practitionerName: locationDetails?.doctors?.find(
              d => d.doctorId === values?.doctor.toString()
            )?.doctorName,
            appointmentDate:
              selectedSlot?.start &&
              moment(selectedSlot.start)
                .tz(selectedSlot.timezone)
                .format('DD-MM-YYYY'),
            appointmentTime: moment(selectedSlot.start)
              .tz(selectedSlot.timezone)
              .format('h:mma'),
          },
          { specialist: isValidSpecialist ? `conversion-${specialist}` : '' }
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, [
    centre?.name,
    locationDetails?.doctors,
    selectedSlot,
    isValidSpecialist,
    specialist,
    values?.doctor,
    values.patientType,
    values?.service,
  ]);

  useEffect(() => {
    if (isEmergencyAppointmentMode) {
      setFieldValue('doctor', 'any');
    }
  }, [values.service, isEmergencyAppointmentMode, setFieldValue]);

  const getReasonIds = useMemo(() => {
    const list = [];

    if (!values.service) {
      return list;
    }
    let doctors = locationDetails?.doctors;

    if (values?.doctor && values?.doctor !== 'any') {
      doctors = doctors?.filter(doctor => values.doctor === doctor.doctorId);
    }

    doctors?.forEach(doctor => {
      const reason = doctor?.doctorReasons.find(
        r => r.reasonName === values.service
      );
      if (reason) {
        list.push(parseInt(reason.reasonId, 10));
      }
    });
    return list;
  }, [values?.service, values?.doctor, locationDetails]);

  const {
    data: slotData,
    loading: slotLoading,
    error: slotError,
  } = usePersistedCacheQuery(LocationSlotsQuery, {
    skip:
      !centre ||
      !centre.practiceID ||
      !values.service ||
      !values.doctor ||
      !getReasonIds.length,
    client,
    variables: {
      input: {
        practiceId: parseInt(centre?.practiceID, 10),
      },
      doctorId: values?.doctor
        ? values.doctor === 'any'
          ? null
          : parseInt(values.doctor, 10)
        : null,
      slotInput: {
        reasonList: getReasonIds || null,
        dateRange: {
          start: now.toISODate(),
          end: end.toISODate(),
        },
      },
    },
  });

  // Handle scroll to calendar blocks once loaded
  useEffect(() => {
    // confirm loaded
    if (selectedSlot) {
      scrollToElement(appointmentConfirmBlock?.current, -100);
      return;
    }

    // date selected scroll to times
    if (slotData && date && !slotLoading && appointmentTimeBlock?.current) {
      scrollToElement(appointmentTimeBlock?.current);
      return;
    }

    // practitioner selected scroll to date
    if (slotData && appointmentDateBlock?.current) {
      scrollToElement(appointmentDetailsBlock.current);
      return;
    }
    // scroll to date block when loading
    if (!slotData && values?.service && values?.doctor) {
      scrollToElement(appointmentDetailsBlock.current);
      return;
    }
  }, [
    slotData,
    appointmentDateBlock,
    date,
    slotLoading,
    appointmentTimeBlock,
    selectedSlot,
    centre?.name,
    values?.service,
    values?.doctor,
  ]);

  const setSlot = useCallback(
    (event, slot) => {
      event?.preventDefault();
      setSelectedSlot(slot);
    },
    [setSelectedSlot]
  );

  // reset form if patientType changes
  useEffect(() => {
    resetForm({
      doctor: '',
      service: '',
    });
    setFieldValue('patientType', values.patientType);
    setSlot(null);
    setDate(null);
    setNextMonths(1);
    setShowNoDoctorsError(false);
  }, [resetForm, setDate, setFieldValue, setSlot, values.patientType]);

  // If service is changed, set date and slot to null as slot ids differ
  // across services. Keep a ref of the service value for when the user
  // navigates back to this page in the sidenav
  useEffect(() => {
    if (serviceRef?.current !== undefined) {
      setSlot(null);
      setDate(null);
      setNextMonths(1);
    }

    if (serviceRef?.current === undefined) {
      serviceRef.current = values.service;
    }
  }, [setDate, setSlot, values.service]);

  // If doctor is changed, set date and slot to null if the previously
  // selected values are not available on the newly selected doctor
  useEffect(() => {
    // !slotLoading ensures this check waits for data to be loaded
    if (!slotLoading && values.doctor && values.doctor !== 'any') {
      if (
        !slotData?.locationDetails?.slots.some(x =>
          isSameDay(date, moment(x.start).startOf('day'))
        )
      ) {
        setSlot(null);
        setDate(null);
        setNextMonths(1);
      }
    }
  }, [
    selectedSlot,
    setSlot,
    date,
    setDate,
    slotLoading,
    slotData?.locationDetails?.slots,
    values.doctor,
  ]);

  const getValidDoctors = useMemo(() => {
    if (!values.service) {
      return [];
    }
    const valid =
      locationDetails?.doctors.filter(doctor => {
        return !!doctor.doctorReasons.find(
          r => r.reasonName === values.service
        );
      }) || [];

    if (!valid.length) {
      setShowNoDoctorsError(true);
    } else {
      if (showNoDoctorsError) {
        setShowNoDoctorsError(false);
      }
    }

    return valid;
  }, [locationDetails?.doctors, showNoDoctorsError, values.service]);

  const getDoctorsQualifications = useCallback(
    doctorId => {
      const doctor = locationDetails?.doctors.find(
        doctor => parseInt(doctorId, 10) === parseInt(doctor.doctorId, 10)
      );
      return doctor?.bio?.qualifications?.map(q => q.qualification).join(', ');
    },
    [locationDetails?.doctors]
  );

  const getDates = useCallback(() => {
    return slotData?.locationDetails?.slots?.reduce((dates, slot) => {
      const slotDate = moment(slot.start).startOf('day');
      if (!dates.find(date => date === slotDate)) {
        dates.push(slotDate);
      }
      return dates;
    }, []);
  }, [slotData?.locationDetails?.slots]);

  useEffect(() => {
    const currentMonth = datePickerRef?.current?.state?.currentMonth.month();
    const endMonth = moment(
      slotData?.locationDetails?.slots[
        slotData?.locationDetails?.slots?.length - 1
      ]?.start
    ).month();
    setShowContactMessage(currentMonth === endMonth);
  }, [slotData?.locationDetails?.slots]);

  const onDateChange = newDate => {
    setDate(newDate);
    setSelectedSlot(null);
  };

  const getSelectedDoctors = useMemo(() => {
    const doctors = [];

    slotData?.locationDetails?.slots?.forEach(slot => {
      if (!doctors.find(doctor => doctor.doctorId === slot.doctor.doctorId)) {
        doctors.push(slot.doctor);
      }
    });

    return doctors;
  }, [slotData?.locationDetails?.slots]);

  const getSelectedSlots = useCallback(
    doctorId => {
      return slotData?.locationDetails?.slots?.filter(slot => {
        return (
          slot.doctor.doctorId === doctorId &&
          isSameDay(moment(slot.start), date)
        );
      });
    },
    [date, slotData?.locationDetails?.slots]
  );

  const SlotSelectionBlocks = useMemo(() => {
    const blocks = [];
    const wrapTimes = (doctor, children, length) => (
      <SlotContainer key={doctor?.doctorId} mt="24px" flexDirection="column">
        <StandardText fontSize="18px" lineHeight="28px" color="#202938">
          {doctor?.doctorName}
        </StandardText>
        <StandardText fontSize="16px" lineHeight="24px" color="#4C5564">
          {getDoctorsQualifications(doctor?.doctorId)}
        </StandardText>
        <TimeGrid data-bottomborder={length > 1}>{children}</TimeGrid>
      </SlotContainer>
    );

    // loop over all doctors and add time selection blocks
    getSelectedDoctors.forEach(doctor => {
      const selectedSlots = getSelectedSlots(doctor.doctorId);
      if (selectedSlots.length) {
        blocks.push(
          wrapTimes(
            doctor,
            selectedSlots.map(slot => (
              <TimeButton
                key={slot.slotId}
                data-selected={slot.slotId === selectedSlot?.slotId}
                onClick={e => setSlot(e, slot)}
              >
                {moment(slot.start).tz(slot.timezone).format('hh:mm A')}
              </TimeButton>
            )),
            getSelectedDoctors.length
          )
        );
      }
    });

    return blocks;
  }, [
    getSelectedDoctors,
    getDoctorsQualifications,
    getSelectedSlots,
    selectedSlot?.slotId,
    setSlot,
  ]);

  const ErrorMessage = ({ error }) => (
    <ThrottleAlert
      error={error}
      ifNotThrottle={
        <FieldWrapper>
          {specialistSearchMode ? (
            <>
              <StandardText
                fontSize="18px"
                lineHeight="28px"
                color="#003959"
                mb="28px"
              >
                Currently there are no available times for this appointment
                type. Click the button below to request a consultation, and
                we'll get back to you as soon as possible to find a time that
                works
              </StandardText>
              <Button
                as="a"
                href="https://google.com.au"
                variant="primary"
                target="_blank"
              >
                Request a consultation
              </Button>
            </>
          ) : (
            <StandardText
              fontSize="18px"
              lineHeight="28px"
              color="#003959"
              mt="28px"
            >
              Currently there are no available times for this appointment type.
              Please phone {centre?.name}{' '}
              {selectedCentre?.details?.phone && (
                <>
                  {'on '}
                  <PhoneNumber
                    bold
                    color="rgb(20, 74, 56)"
                    inline
                    phone={selectedCentre?.details?.phone}
                  />{' '}
                </>
              )}
              for further information.
            </StandardText>
          )}
        </FieldWrapper>
      }
    />
  );

  const ContactMessage = () => (
    <StandardText fontSize="16px" sx={{ paddingTop: 24 }}>
      To book an appointment further in advance, please contact {centre?.name}
      {selectedCentre?.details?.phone && (
        <>
          {' '}
          on{' '}
          <PhoneNumber
            color="rgb(20, 74, 56)"
            inline
            phone={selectedCentre?.details?.phone}
          />{' '}
        </>
      )}
      to book
    </StandardText>
  );

  const BackToStep1 = useCallback(
    () => (
      <StandardText sx={{ paddingTop: 24 }}>
        Can't find the time that suits?&nbsp;&nbsp;
        <Button
          onClick={() =>
            setStep(0, {
              patientType: values.patientType,
              service: values.service,
            })
          }
          sx={{
            background: 'transparent',
            color: theme.colors.primary,
            border: 'none',
            padding: 0,
            fontFamily: 'Buenos Aires',
            marginBottom: '8px',
            fontSize: 16,
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.colors.primary,
            },
            '&:focus': {
              backgroundColor: 'transparent',
              color: theme.colors.primary,
            },
          }}
        >
          Search for an appointment nearby
        </Button>
      </StandardText>
    ),
    [theme.colors.primary, setStep, values.patientType, values.service]
  );

  const allDates = !slotError && slotData ? getDates() : [];
  const getDateSize = useMemo(() => {
    if (isSmallPhone) {
      return 40;
    }

    if (isSmallPhone) {
      return 45;
    }

    if (isMobile) {
      return 55;
    }

    if (isTablet) {
      return 75;
    }

    if (isLtDesktop) {
      return 80;
    }

    return 80;
  }, [isSmallPhone, isMobile, isTablet, isLtDesktop]);

  return (
    <FormWrapper
      title="Select an appointment"
      alert={
        !isEmergencyAppointmentMode && (
          <Alert variant="info">
            <span>In case of an emergency call the centre on</span>{' '}
            <PhoneNumber
              inline
              bold
              color="primary"
              phone={selectedCentre?.details?.phone}
            />
          </Alert>
        )
      }
    >
      <FieldWrapper title="Patient type">
        <Radio
          name="patientType"
          onBlur={handleOnBlur}
          choices={[
            {
              label: 'Existing patient',
              value: 'existing',
            },
            {
              label: 'New patient',
              value: 'new',
            },
          ]}
        />
      </FieldWrapper>

      <FieldWrapper
        title="Appointment details"
        innerRef={appointmentDetailsBlock}
      >
        <Select
          name="service"
          label="Service"
          onBlur={handleOnBlur}
          choices={serviceOptions}
          onChange={e =>
            specialistSearchMode && onChangeSpecialist(e.target.value)
          }
        />
        {values?.service && (
          <Select
            name="doctor"
            label="Practitioner"
            choices={[
              { label: 'Select an option', value: '' },
              ...(getValidDoctors?.length
                ? [
                    {
                      label: 'Any practitioner (first available)',
                      value: 'any',
                    },
                  ]
                : []),
              ...(isEmergencyAppointmentMode
                ? []
                : getValidDoctors?.map(reason => ({
                    label: reason.doctorName,
                    value: reason.doctorId,
                  }))),
            ]}
            help="Select a practitioner to filter available dates / times"
            labelProps={{
              mt: '16px',
            }}
          />
        )}
      </FieldWrapper>
      {!slotError ? (
        <>
          {slotData && !!allDates?.length && (
            <FieldWrapper
              title="Appointment date"
              innerRef={appointmentDateBlock}
              flexProps={{ gridGap: 0 }}
            >
              <Box css={calendarStyles}>
                <DayPickerSingleDateController
                  ref={datePickerRef}
                  date={date}
                  minDate={moment(now.toISODate())}
                  maxDate={moment(end.toISODate())}
                  onDateChange={onDateChange}
                  focused={true}
                  numberOfMonths={2}
                  firstDayOfWeek={1}
                  onNextMonthClick={() => {
                    setNextMonths(nextMonths + 1);
                  }}
                  onPrevMonthClick={() => {
                    setNextMonths(nextMonths - 1);
                  }}
                  noNavNextButton={
                    fourMonths ? nextMonths >= 3 : nextMonths >= 2
                  }
                  noNavPrevButton={nextMonths <= 1}
                  verticalHeight={'100%'}
                  hideKeyboardShortcutsPanel={true}
                  daySize={getDateSize}
                  transitionDuration={0}
                  initialVisibleMonth={() =>
                    date ? date : moment(now.toISODate())
                  }
                  renderDayContents={day => (
                    <Flex
                      width="100%"
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex
                        className="actual-date"
                        justifyContent="center"
                        alignItems="center"
                      >
                        &nbsp;{day.format('D')}&nbsp;
                      </Flex>
                    </Flex>
                  )}
                  isDayBlocked={day =>
                    !allDates.some(date => isSameDay(day, date))
                  }
                  orientation={'vertical'}
                />
                {showContactMessage ? <ContactMessage /> : null}
                {!isEmergencyAppointmentMode &&
                  !selectedCentre?.standbyList && <BackToStep1 />}
              </Box>
            </FieldWrapper>
          )}
          {!slotData &&
            values?.service &&
            values?.doctor &&
            (date && !slotLoading && !allDates.length ? (
              <FieldWrapper innerRef={appointmentDateBlock}>
                <ErrorMessage />
              </FieldWrapper>
            ) : (
              <FieldWrapper innerRef={appointmentDateBlock}>
                <Loader />
              </FieldWrapper>
            ))}
          {slotData &&
            date &&
            (!slotLoading ? (
              <FieldWrapper
                title="Appointment time"
                innerRef={appointmentTimeBlock}
              >
                {!!SlotSelectionBlocks.length ? (
                  SlotSelectionBlocks
                ) : (
                  <ErrorMessage />
                )}
              </FieldWrapper>
            ) : (
              <Loader />
            ))}
          {!date && slotData && !allDates.length && (
            <FieldWrapper>
              <ErrorMessage />
            </FieldWrapper>
          )}
          {values.service &&
            values.doctor &&
            date &&
            selectedSlot &&
            !slotLoading && (
              <FieldWrapper
                innerRef={appointmentConfirmBlock}
                borderColor={theme.colors.primary}
              >
                <Paragraph
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  Your selected appointment
                </Paragraph>
                <Paragraph
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {moment(selectedSlot?.start)
                    .tz(selectedSlot.timezone)
                    .format('dddd DD MMMM [at] LT')}{' '}
                  with {selectedSlot?.doctor?.doctorName?.split(' |')?.[0]}
                </Paragraph>
              </FieldWrapper>
            )}
        </>
      ) : (
        <ErrorMessage error={slotError} />
      )}

      {showNoDoctorsError && (
        <FieldWrapper>
          <ErrorMessage />
        </FieldWrapper>
      )}

      <FormButton
        onClick={() => setStep(nextStep, values)}
        disabled={
          !values.service ||
          !values.doctor ||
          !date ||
          !selectedSlot ||
          slotLoading
        }
        text={`Continue to ${
          nextStep === 2 ? 'covid screening' : 'patient details'
        }`}
      />
    </FormWrapper>
  );
};

SelectAppointment.propTypes = {
  locationDetails: PropTypes.shape({
    doctors: PropTypes.arrayOf(
      PropTypes.shape({
        doctorName: PropTypes.string,
        doctorId: PropTypes.string,
        bio: PropTypes.shape({
          qualifications: PropTypes.arrayOf(
            PropTypes.shape({
              __typename: PropTypes.string,
              qualification: PropTypes.string,
              university: PropTypes.string,
              graduationYear: PropTypes.string,
            })
          ),
        }),
        doctorReasons: PropTypes.arrayOf(
          PropTypes.shape({
            reasonId: PropTypes.string,
            reasonName: PropTypes.string,
          })
        ),
      })
    ),
  }),
  setStep: PropTypes.func.isRequired,
  setSelectedSlot: PropTypes.func,
  selectedSlot: PropTypes.shape({
    doctor: PropTypes.shape({
      doctorName: PropTypes.string,
      doctorId: PropTypes.string,
    }),
    start: PropTypes.string.isRequired,
    end: PropTypes.string,
  }),
  selectedCentre: PropTypes.shape({
    practiceID: PropTypes.string.isRequired,
  }),
};

export default SelectAppointment;
