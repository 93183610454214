import { Flex, Button, Paragraph } from 'theme-ui';
import Loader from 'components/common/Loader';
import ThrottleAlert from './ThrottleAlert';

const FormButton = ({
  onClick,
  disabled,
  text,
  type = 'button',
  isSubmitting = false,
  submitError = '',
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: ['24px', '56px', '56px'],
      }}
    >
      {isSubmitting ? (
        <Loader />
      ) : (
        <Button
          variant="primary"
          onClick={onClick}
          disabled={disabled}
          type={type}
        >
          {text}
        </Button>
      )}
      {submitError && (
        <ThrottleAlert
          error={submitError}
          ifNotThrottle={
            <Paragraph variant="error">{submitError?.message}</Paragraph>
          }
        />
      )}
    </Flex>
  );
};

export default FormButton;
