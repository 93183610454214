import { lighten } from 'polished';
import breakpoints from 'lib/breakpoints';

const bps = breakpoints;
const mq = {};
Object.keys(bps).forEach(bp => {
  mq[bp] = `@media (min-width: ${bps[bp]})`;
  mq[`${bp}Inverted`] = `@media (max-width: ${bps[bp]})`;
});

const defaultButton = {
  fontSize: '1rem',
  borderRadius: '100px',
  padding: '1rem 2rem',
  transition:
    '0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s color ease-in-out',

  boxSizing: 'border-box',
  cursor: 'pointer',
  fontWeight: 'bold',
  letterSpacing: '-0.02em',
  transitionDuration: '0.25s',
  transitionTimingFunction: 'ease-in-out',
  transitionProperty: 'background',
  border: 'medium none',
  verticalAlign: 'middle',
  lineHight: '20px',
  '&:focus': {
    outlineOffset: '0.125em',
    outline: 'rgb(82, 81, 153) solid 3px',
  },
};

const defaultAlert = {
  fontSize: 2,
  fontWeight: 'normal',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '4px',
  color: 'white',
  backgroundColor: 'rgb(255, 255, 255)',
  boxShadow: 'rgba(0, 0, 0, 0.13) 0px 4px 8px 0px',
  border: 'none',
  borderLeft: '0.25rem solid rgb(0, 91, 185)',
  paddingInline: ['16px', '24px'],
  paddingBlock: ['12px', '18px'],
};

const theme = {
  mq,
  breakpoints: ['576px', '768px', '992px', '1200px'],
  sizes: {
    container: '966px',
  },
  layout: {
    container: {
      maxWidth: ['100%', '540px', '720px', '960px', '1625px'],
      px: '15px',
    },
    offsetContainer: {
      maxWidth: '960px',
      px: '15px',
    },
    bannerContainer: {
      maxWidth: ['100%', '540px', '720px', '960px', '1625px'],
      px: '15px',
    },
  },
  fonts: {
    sans: '"Buenos Aires", Arial, sans-serif',
    body: '"Buenos Aires", Arial, sans-serif',
    heading: '"Grenette Pro", Arial, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 29, 36, 48, 60],
  fontWeights: {
    body: 400,
    heading: 500,
    light: 300,
    normal: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.375,
  },
  colors: {
    text: 'rgb(67, 67, 67)',
    background: '#F9FAFC',
    primary: 'rgb(20, 74, 56)',
    secondary: 'rgb(130, 229, 120)',
    grey200: '#F3F4F6',
    grey400: '#D2D5DC',
    grey500: '#9CA2AE',
    grey600: '#6B7380',
    grey700: '#4C5564',
    grey1000: '#111828',
    blue200: 'rgb(0, 91, 185)',
    sydneySky: '#7CA2DB',
    error: 'rgb(200, 20, 20)',
    errorLight: lighten(0.5, '#D0021B'),
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    disclaimer: {
      variant: 'paragraph',
      fontSize: 1,
      color: 'grey600',
    },
    error: {
      variant: 'paragraph',
      // fontSize: [0, 1, 1],
      color: 'error',
      marginTop: '8px',
      fontSize: '0.875rem',
      lineHeight: '1.5',
      fontWeight: 'bold',
    },
    optional: {
      variant: 'paragraph',
      color: 'grey600',
    },
    help: {
      variant: 'paragraph',
      fontSize: [0, 1, 1],
      color: 'grey600',
      marginTop: '8px',
    },
    header: {
      variant: 'paragraph',
      fontSize: 2,
      fontWeight: 'bold',
      color: 'white',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      variant: 'text.heading',
      lineHeight: 1.25,
      fontWeight: 700,
      fontSize: ['28px', 9],
      color: 'white',
    },
    h2: {
      variant: 'text.heading',
      lineHeight: 1.25,
      fontWeight: 700,
      fontSize: 7,
      color: 'primary',
    },
    h3: {
      variant: 'text.heading',
      fontSize: 6,
      fontFamily: 'body',
    },
    mapTitle: {
      variant: 'text.heading',
      fontSize: 6,
      fontFamily: 'heading',
    },
  },
  alerts: {
    info: {
      ...defaultAlert,
      color: 'text',
      borderColor: 'blue200',
    },
    error: {
      ...defaultAlert,
      color: 'text',
      borderColor: 'error',
    },
  },
  forms: {
    label: {
      fontSize: 2,
      color: 'rgb(20, 74, 56)',
      fontWeight: 'bold',
      lineHeight: '1.5',
      marginBottom: ['0px', '8px'],
      width: 'inherit',
    },
    select: {
      fontSize: 2,
      backgroundColor: 'rgb(241, 241, 241)',
      borderColor: 'grey400',
      '&::placeholder': {
        color: 'grey600',
      },
      padding: ['0.5rem 2rem 0.5rem 0.5rem', '1rem 3.5rem 1rem 1rem'],
      lineHeight: '1.5',
      width: '100%',
      maxWidth: '100%',
      margin: ['1rem 0 1.5rem', '1rem 0 3rem'],
      border: 'medium none',
      borderRadius: '0px',
      boxShadow: 'rgb(138, 138, 138) 0px -2px 0px 0px inset',
      appearance: 'none',
      backgroundImage:
        'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjRweCcgaGVpZ2h0PScyNHB4JyB2aWV3Qm94PScwIDAgMjQgMjQnIHZlcnNpb249JzEuMScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyB4bWxuczp4bGluaz0naHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayc+PHRpdGxlPmljb24vc3lzdGVtL2Fycm93X2Rvd248L3RpdGxlPjxnIGlkPSdpY29uL3N5c3RlbS9hcnJvd19kb3duJyBzdHJva2U9J25vbmUnIHN0cm9rZS13aWR0aD0nMScgZmlsbD0nbm9uZScgZmlsbC1ydWxlPSdldmVub2RkJz48cGF0aCBkPSdNNy4zODc4MTM2LDEyLjEzNTM2MzcgTDE4LjAwNzQ4MzUsMS41MTU2OTM4MSBDMTguMzAwMzc2NywxLjIyMjgwMDU5IDE4LjMwMDM3NjcsMC43NDc5MjY4NTggMTguMDA3NDgzNSwwLjQ1NTAzMzY0IEMxNy43MTQ1OTAzLDAuMTYyMTQwNDIxIDE3LjIzOTcxNjYsMC4xNjIxNDA0MjEgMTYuOTQ2ODIzMywwLjQ1NTAzMzY0IEw1Ljc5NjgyMzM0LDExLjYwNTAzMzYgQzUuNTAzOTMwMTIsMTEuODk3OTI2OSA1LjUwMzkzMDEyLDEyLjM3MjgwMDYgNS43OTY4MjMzNCwxMi42NjU2OTM4IEwxNi45NDY4MjMzLDIzLjgxNTY5MzggQzE3LjIzOTcxNjYsMjQuMTA4NTg3IDE3LjcxNDU5MDMsMjQuMTA4NTg3IDE4LjAwNzQ4MzUsMjMuODE1NjkzOCBDMTguMzAwMzc2NywyMy41MjI4MDA2IDE4LjMwMDM3NjcsMjMuMDQ3OTI2OSAxOC4wMDc0ODM1LDIyLjc1NTAzMzYgTDcuMzg3ODEzNiwxMi4xMzUzNjM3IFonIGZpbGw9JyM0NDQ0NDQnIGZpbGwtcnVsZT0nbm9uemVybycgdHJhbnNmb3JtPSd0cmFuc2xhdGUoMTEuOTAyMTUzLCAxMi4xMzUzNjQpIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTExLjkwMjE1MywgLTEyLjEzNTM2NCkgJz48L3BhdGg+PC9nPjwvc3ZnPg==")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 1rem top 50%',
      backgroundSize: '1.25em',
      cursor: 'pointer',
    },
    input: {
      backgroundColor: 'rgb(241, 241, 241)',
      color: 'text',
      borderColor: 'grey400',
      padding: ['0.5rem 2rem 0.5rem 0.5rem', '1rem 3.5rem 1rem 1rem'],
      fontSize: 2,
      lineHeight: '1.5',
      border: '0px none',
      borderRadius: '0px',
      appearance: 'textfield',
      boxShadow: 'rgb(138, 138, 138) 0px -2px 0px 0px inset',
      boxSizing: 'border-box',
      margin: '1rem 0',
      '&:focus': {
        outline: 'rgb(82, 81, 153) solid 3px',
        outlineOffset: '0.125em',
      },
      '&::placeholder': {
        color: 'grey600',
      },
      '&[type="date"]': {
        fontFamily: 'body',
        minHeight: '50px',
        '::-webkit-date-and-time-value': {
          textAlign: 'left',
        },
      },
    },
    textarea: {
      fontSize: 2,
      fontFamily: 'body',
      backgroundColor: 'rgb(241, 241, 241)',
      borderColor: 'grey400',
      padding: '12px',
      '&::placeholder': {
        color: 'grey600',
      },
      '&:focus': {
        outline: 'rgb(82, 81, 153) solid 3px',
        outlineOffset: '0.125em',
      },
    },
  },
  buttons: {
    primary: {
      ...defaultButton,
      color: 'primary',
      bg: 'secondary',
      border: 'medium none',
      fontSize: '1rem',
      lineHeight: '20px',
      fontWeight: 'bold',
      borderRadius: '100px',
      '&:hover, &:focus': {
        bg: 'rgb(90, 221, 77)',
      },
      '&:disabled': {
        color: 'rgba(20, 74, 56, 0.6)',
        backgroundColor: 'rgba(130, 229, 120, 0.6)',
        cursor: 'not-allowed',
      },
    },
    secondary: {
      ...defaultButton,
      color: 'primary',
      bg: 'white',
      border: 'medium none',
      borderColor: 'primary',
      boxShadow: 'currentcolor 0px 0px 0px 0.125em inset',
      padding: '1rem 2rem',
      fontSize: '1rem',
      lineHeight: '20px',
      fontWeight: 'bold',
      borderRadius: '100px',
      '&:hover, &:focus': {
        color: 'rgb(9, 34, 26)',
      },
      '&:disabled': {
        bg: 'grey400',
        borderColor: 'grey400',
        cursor: 'not-allowed',
      },
    },
    minimal: {
      appearance: 'none',
      border: 'none',
      margin: 0,
      padding: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  },
};

export default theme;
