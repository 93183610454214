import { Checkbox as ThemeCheckbox, Label, Box, Paragraph } from 'theme-ui';
import { Field, useFormikContext } from 'formik';

const Checkbox = ({ name, label, labelProps, ...props }) => {
  const { touched, errors, values } = useFormikContext();

  return (
    <Box>
      <Label sx={{ ...labelProps }}>
        <Field as={ThemeCheckbox} name={name} id={name} {...props} />
        {label}
      </Label>
      {(touched?.[name] || values?.[name]) && errors?.[name] && (
        <Paragraph variant="error">{errors?.[name]}</Paragraph>
      )}
    </Box>
  );
};

export default Checkbox;
