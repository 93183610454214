import { Alert as ThemeAlert, Box } from 'theme-ui';
import InfoIcon from 'components/common/Icons/InfoIcon';
import ErrorIcon from './Icons/ErrorIcon';

const getAlert = variant =>
  ({
    info: {
      icon: InfoIcon,
      color: 'rgb(0, 91, 185)',
    },
    error: {
      icon: ErrorIcon,
      color: '#D0021B',
    },
  }[variant]);

const Alert = ({ variant = 'info', children }) => {
  const alert = getAlert(variant);
  return (
    <ThemeAlert
      variant={variant}
      sx={{ flexDirection: 'row', alignItems: 'flex-start' }}
    >
      <alert.icon
        sx={{
          marginRight: '16px',
          marginTop: '2px',
          height: '100%',
          display: 'flex',
          color: alert.color,
        }}
      />
      <Box>{children}</Box>
    </ThemeAlert>
  );
};

export default Alert;
