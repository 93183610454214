import React, { useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FormWrapper from 'components/common/FormWrapper';
import FormButton from 'components/common/FormButton';
import Alert from 'components/common/Alert';
import { FieldWrapper } from 'components/common/FieldCard';
import { Radio } from 'components/common/Fields';
import scrollToElement from 'lib/scrollToElement';

const CovidScreening = ({ setStep }) => {
  const { values, handleChange } = useFormikContext();
  const Step2Ref = useRef(null);
  const Step3Ref = useRef(null);
  const Step4Ref = useRef(null);
  const scrollToRef = (e, ref) => {
    scrollToElement(ref.current);
    handleChange(e);
  };

  const covidValid = useMemo(() => {
    return values.covid1 && values.covid2 && values.covid3 && values.covid4;
  }, [values]);

  return (
    <FormWrapper
      title="COVID-19 screening"
      alert={
        <Alert variant="info">
          These questions are in line with the Department of Health Guidelines
          and help our practice with the management of COVID-19.
        </Alert>
      }
    >
      <FieldWrapper>
        <Radio
          name="covid1"
          label="Are you experiencing respiratory symptoms (i.e. sore throat, cough) or a fever 37.5°C?"
          choices={[
            {
              label: 'Yes',
              value: 'Yes',
            },
            {
              label: 'No',
              value: 'No',
            },
          ]}
          onChange={e => scrollToRef(e, Step2Ref)}
        />
      </FieldWrapper>

      <FieldWrapper innerRef={Step2Ref}>
        <Radio
          name="covid2"
          label="Have you been in contact with a probable, suspected, or confirmed case of COVID-19?"
          choices={[
            {
              label: 'Yes',
              value: 'Yes',
            },
            {
              label: 'No',
              value: 'No',
            },
          ]}
          onChange={e => scrollToRef(e, Step3Ref)}
        />
      </FieldWrapper>

      <FieldWrapper innerRef={Step3Ref}>
        <Radio
          name="covid3"
          label="Have you been in contact with any person with undiagnosed viral respiratory illness?"
          choices={[
            {
              label: 'Yes',
              value: 'Yes',
            },
            {
              label: 'No',
              value: 'No',
            },
          ]}
          onChange={e => scrollToRef(e, Step4Ref)}
        />
      </FieldWrapper>

      <FieldWrapper innerRef={Step4Ref}>
        <Radio
          name="covid4"
          label="Do you live, or have you travelled through, an area with elevated risk of community transmission, as defined by public health authorities?"
          choices={[
            {
              label: 'Yes',
              value: 'Yes',
            },
            {
              label: 'No',
              value: 'No',
            },
          ]}
          onChange={e => scrollToRef(e, Step4Ref)}
        />
      </FieldWrapper>

      <FormButton
        onClick={() => setStep(3, values)}
        disabled={!covidValid}
        text="Continue to patient details"
      />
    </FormWrapper>
  );
};

CovidScreening.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default CovidScreening;
