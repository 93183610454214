import { useQuery } from '@apollo/client';

// https://github.com/apollographql/apollo-client/issues/8669
const usePersistedCacheQuery = (query, options) => {
  const result = useQuery(query, {
    ...options,
    fetchPolicy: 'cache-and-network',
  });

  const isActuallyLoading =
    result.loading &&
    !result.client.readQuery({
      query,
      variables: options?.variables,
    });

  return { ...result, loading: isActuallyLoading };
};

export default usePersistedCacheQuery;
