import React from 'react';
import { useThemeUI, Box } from 'theme-ui';

const LocationIcon = ({ width, sx, color }) => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      sx={{
        minWidth: width || '20px',
        width: width || '20px',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <path
        stroke={color || theme.colors.primary}
        stroke-linejoin="round"
        d="M12 1C8 1 4 4 4 8.7 4 15.5 12 23 12 23s8-7.5 8-14.3C20 4 16 1 12 1z"
      />
      <path
        stroke={color || theme.colors.primary}
        d="M12 13c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
      />
    </Box>
  );
};

export default LocationIcon;
