import { Box, useThemeUI } from 'theme-ui';

const InfoIcon = ({ width, color, backgroundColor, sx }) => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 24"
      fill={color || theme.colors.primary}
      sx={{
        minWidth: width || '32px',
        width: width || '32px',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"></path>
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M11.891 24.007c6.571 0 11.898-5.327 11.898-11.898S18.462.211 11.89.211-.007 5.538-.007 12.11 5.32 24.007 11.891 24.007zm0-1.5c-5.743 0-10.398-4.655-10.398-10.398 0-5.743 4.655-10.398 10.398-10.398 5.743 0 10.398 4.655 10.398 10.398 0 5.743-4.655 10.398-10.398 10.398zm0-14.866a1.122 1.122 0 100-2.243 1.122 1.122 0 000 2.243zM11.89 18.79a.75.75 0 00.751-.748l.014-7.677a.75.75 0 00-1.5-.003l-.014 7.677c0 .414.334.75.749.751z"
        ></path>
      </g>
    </Box>
  );
};

export default InfoIcon;
