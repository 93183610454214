import { Box, Flex, Paragraph, Container } from 'theme-ui';
import ClockIcon from 'components/common/Icons/ClockIcon';
import CalendarIcon from 'components/common/Icons/CalendarIcon';

import { appointmentsAvailable, openingHours } from 'lib/appointments';
import LocationIcon from './Icons/LocationIcon';

const Banner = ({ centre, showLocation = true }) => {
  const hours = openingHours(centre);
  const appointments = appointmentsAvailable(centre);

  if (!hours && !appointments) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'primary',
        color: 'white',
        padding: '12px 0',
      }}
    >
      <Container variant="bannerContainer">
        <Flex
          sx={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            gridGap: '12px',
          }}
        >
          {showLocation && (
            <Flex>
              <LocationIcon color="white" />
              <Paragraph variant="header" sx={{ marginLeft: '16px' }}>
                {centre.name}
              </Paragraph>
            </Flex>
          )}
          {hours && (
            <Flex
              sx={{
                flexDirection: 'row',
              }}
            >
              <ClockIcon color="white" />
              <Paragraph variant="header" sx={{ marginLeft: '16px' }}>
                {hours}
              </Paragraph>
            </Flex>
          )}
          {appointments && (
            <Flex
              sx={{
                flexDirection: 'row',
              }}
            >
              <CalendarIcon color="white" />
              <Paragraph variant="header" sx={{ marginLeft: '16px' }}>
                {appointments}
              </Paragraph>
            </Flex>
          )}
        </Flex>
      </Container>
    </Box>
  );
};

export default Banner;
