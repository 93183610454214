import { Box, useThemeUI } from 'theme-ui';

const CalendarIcon = ({ width, color, sx }) => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      sx={{
        minWidth: width || '20px',
        width: width || '20px',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <g
        stroke={color || theme.colors.primary}
        strokeLinecap="round"
        strokeWidth="1.5"
        clipPath="url(#clip0)"
      >
        <path strokeLinejoin="round" d="M1 3H19V19H1z" />
        <path d="M5.25 5.25L5.25.75M14.75.75L14.75 5.25" />
        <path strokeLinejoin="round" d="M1.75 8.25L18.25 8.25" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={theme.colors.white} d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </Box>
  );
};

export default CalendarIcon;
