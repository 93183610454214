import React from 'react';
import Alert from './Alert';

const ThrottleAlert = ({ error, ifNotThrottle }) => {
  const isThrottledError =
    error?.graphQLErrors?.[0]?.extensions?.code === 'THROTTLED_ERROR';
  if (!isThrottledError && ifNotThrottle) {
    return ifNotThrottle;
  }
  return (
    <Alert variant="error">
      {isThrottledError
        ? 'Too many requests. Please try again in a few minutes.'
        : error.message}
    </Alert>
  );
};

export default ThrottleAlert;
