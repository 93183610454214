import React, { useEffect } from 'react';
import { Select as ThemeSelect, Box, Label, Flex, Paragraph } from 'theme-ui';
import { Field, useFormikContext } from 'formik';
import slugify from 'slugify';

const Select = ({
  name,
  label,
  choices,
  optional = false,
  placeholder = 'Select an option',
  help,
  labelProps,
  submitFormOnChange = false,
  onChange,
  ...props
}) => {
  const { values, setFieldValue, touched, errors, submitForm } =
    useFormikContext();

  useEffect(() => {
    if (name === 'service' && values.service) {
      if (!choices.find(c => c.value === values.service)) {
        setFieldValue('service', '');
      }
    }
    if (name === 'doctor' && values.doctor) {
      if (!choices.find(c => c.value === values.doctor)) {
        setFieldValue('doctor', '');
      }
    }
  }, [name, choices, values, setFieldValue]);

  const handleChange = e => {
    setFieldValue(name, e.target.value);
    onChange && onChange(e);
    submitFormOnChange && submitForm();
  };

  return (
    <Box>
      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...labelProps,
        }}
      >
        <Label htmlFor={name}>{label}</Label>
        {optional && <Paragraph variant="optional">(Optional)</Paragraph>}
      </Flex>
      <Field
        as={ThemeSelect}
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={handleChange}
        arrow={<Box />}
        {...props}
      >
        {choices?.map(choice => (
          <option
            id={slugify(`${name}-${choice?.value}`)}
            key={choice?.value}
            value={choice?.value}
          >
            {choice?.label}
          </option>
        ))}
      </Field>
      {help && <Paragraph variant="help">{help}</Paragraph>}
      {touched?.[name] && errors?.[name] && (
        <Paragraph variant="error">{errors?.[name]}</Paragraph>
      )}
    </Box>
  );
};

export default Select;
