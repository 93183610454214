import React from 'react';
import { Flex } from 'theme-ui';
import { ReactComponent as LoaderIcon } from 'icons/loader.svg';

const Loader = ({ fullScreen, ...props }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: fullScreen ? '100vh' : '100%',
      }}
      ref={props?.innerRef}
      {...props}
    >
      <LoaderIcon />
    </Flex>
  );
};

export const Loader2 = ({ show, children, innerRef, ...props }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
      ref={innerRef}
      {...props}
    >
      {show && <LoaderIcon />}
      {!show && children}
    </Flex>
  );
};

export default Loader;
