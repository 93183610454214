import { useMemo } from 'react';
import useQueryParams from './useQueryParams';
import { unionBy } from 'lodash';

const asServiceOption = ({ title, value }) => ({ label: title, value });

const useSpecialistServiceMode = ({ reasonMapping = {} }) => {
  const { queryParams, setQueryParams } = useQueryParams();
  const specialist = queryParams.specialist;

  const specialistServices = useMemo(() => {
    const { newSpecialist = [], existingSpecialist = [] } = reasonMapping;
    const uniqueServices = unionBy(newSpecialist, existingSpecialist, 'value');

    return uniqueServices.map(asServiceOption);
  }, [reasonMapping]);

  const isValidSpecialist = useMemo(() => {
    const byValue = val => item => item.value === val;
    return specialist && specialistServices.some(byValue(specialist));
  }, [specialistServices, specialist]);

  const onChangeSpecialist = newValue => {
    if (specialist !== newValue) {
      setQueryParams({ specialist: newValue }, { replace: true });
    }
  };

  const specialistSearchMode = !!(
    specialistServices?.length && specialist !== undefined
  );

  return {
    specialist,
    specialistSearchMode,
    isValidSpecialist,
    specialistServices,
    asServiceOption,
    onChangeSpecialist,
  };
};

export default useSpecialistServiceMode;
