import React from 'react';
import { useThemeUI, Box } from 'theme-ui';

const ErrorIcon = ({ className, width = '20px', sx }) => {
  const { theme } = useThemeUI();
  return (
    <Box
      as="svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      sx={{
        width: width,
        minWidth: width,
        ...sx,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"></path>
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M12.008.022c6.602 0 11.953 5.352 11.953 11.953 0 6.602-5.351 11.954-11.953 11.954-6.602 0-11.953-5.352-11.953-11.954C.055 5.374 5.406.022 12.008.022zm0 1.5c-5.773 0-10.453 4.68-10.453 10.453 0 5.774 4.68 10.454 10.453 10.454s10.453-4.68 10.453-10.454c0-5.773-4.68-10.453-10.453-10.453zM6.995 6.964a.75.75 0 011.061 0l3.95 3.95 3.952-3.95a.75.75 0 01.976-.073l.085.073a.75.75 0 010 1.06l-3.951 3.951 3.952 3.951a.75.75 0 01.072.977l-.072.084a.75.75 0 01-1.061 0l-3.952-3.952-3.95 3.952a.75.75 0 01-.976.073l-.085-.073a.75.75 0 010-1.06l3.95-3.952-3.95-3.95a.75.75 0 01-.073-.977z"
        ></path>
      </g>
    </Box>
  );
};

export default ErrorIcon;
